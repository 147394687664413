import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderModules } from 'src/utils/renderModules'

import { useSetPage } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'

import { ProjectHero } from 'src/components/project/hero'

export interface ProjectProps {
  pageContext: {
    main: {
      modules: [],
      slug: {
        current: string
      },
      title: string
    },
    meta: {}
  }
  preview?: boolean
}

const Project = ({
  pageContext,
  preview = false
}: ProjectProps) => {
  const setPage = useSetPage()
  const { main } = pageContext
  const {
    modules,
    slug,
    meta,
  } = pageContext
  useEffect(() => {
    setPage(pageContext.main.slug)
  }, [0])

  console.log(pageContext)

  const projecUrl = `projects/${slug}`
  return (
    <div className='ac x'>
      {preview && (
        <div className='bcblue ac cw x p1'>This is a Preview</div>
      )}
      
      <SEO metaInfo={meta} pagePath={projecUrl} />
      <div className={cx('mxa x al site__main', pageContext.main.slug.current)}>
      <ProjectHero main={pageContext.main} />
        {RenderModules(modules)}
      </div>
    </div>
  )
}

export default Project