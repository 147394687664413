import React from 'react'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

export const ProjectHero = ({ project, main: { title, projectDescription, mainImage }}: {
  main: {
    title?: string
    subTitle?: string
    slug: {}
    projectDescription?: []
    mainImage: {
      asset: {
        _id: string
      }
    }
  }
  project: {
    projectId: number
  }
}) => {
  return (
    <div className='project__hero'>
      <div className='container--xl p1 outer mxa'>
        <div className='row df aic'>
          <div className='col c12 c6--800 x'>
            <Image className='x' imageId={mainImage.asset._id} alt={title} />
          </div>
          <div className='col c12 c6--800'>
            <div className='p4--800'>
              <h1 className='mb2'>{title}</h1>
              {projectDescription && (<BlockContent blocks={projectDescription} serializers={Serializer} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}